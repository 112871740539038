import { Component, OnInit } from '@angular/core';
import { Noticia } from 'src/app/classes/noticia';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  noticias: Noticia[] = [];

  
  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getNoticiasBlog().subscribe( (data: Noticia[]) => {
      this.noticias = data;
      console.log(this.noticias);
      
    });
  }


}
