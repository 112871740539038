<div class="row">

  <div class="col-md-4">
    <p class="h2">Nuestro Blog</p>
    <p class="text-muted">Sigue nuestro contenido y mantente informado</p>
    <a class="btn btn-outline-primary" routerLink="blog">Ver todas las noticias</a>
  </div>

  <div class="col-md-8">
    <div class="row g-md-4 gy-3">
      <div class="col-md-6" *ngFor="let noticia of noticias">
        <div class="shadow-sm rounded h-100 bg-white position-relative">
          <img class="imageNoticiaCardHome rounded-top" [src]="noticia.image" alt="">
          <div class="p-3 p-md-4 rounded-bottom pt-2 pt-md-3">
            <div class="rounded-pill px-2 py-1 bg-warning d-inline-flex mb-2 extraSmall">{{noticia.fecha.toDate() | date: 'mediumDate' }}</div>
            <p class="h6 mb-0">{{noticia.titulo}}</p>
            <!-- <p class="small text-muted mb-0">{{noticia.subtitulo}}</p> -->
          </div>
          <a class="stretched-link" [routerLink]=" 'blog/' + noticia.id "></a>
        </div>
      </div>
    </div>
  </div>

</div>