<div class="bg-primary">
  <div class="container py-4 py-md-6">
    <div class="row pb-4">
      <div class="col-md-4">
        <img class="logoSeguros360 mb-3" src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logoEaglesWhite.svg?alt=media&token=504a64cd-5790-48a1-bf21-5efddb61a3a7" alt="">
        <div>
          <a class="btn btn-outline-light btn-circle me-2" href="https://www.facebook.com/Eagles-Corredores-de-Seguros-SAC-100946348651663" target="blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
            </svg>
          </a>
          <a class="btn btn-outline-light btn-circle me-2" href="https://www.linkedin.com/company/eagles-corredores-de-seguros-sac" target="blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <rect x="4" y="4" width="16" height="16" rx="2" />
              <line x1="8" y1="11" x2="8" y2="16" />
              <line x1="8" y1="8" x2="8" y2="8.01" />
              <line x1="12" y1="16" x2="12" y2="11" />
              <path d="M16 16v-3a2 2 0 0 0 -4 0" />
            </svg>
          </a>
          <a class="btn btn-outline-light btn-circle" href="https://instagram.com/seguros360.pe?utm_medium=copy_link" target="blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="4" y="4" width="16" height="16" rx="4"></rect>
              <circle cx="12" cy="12" r="3"></circle>
              <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
           </svg>
          </a>
        </div>
      </div>
      <div class="d-flex flex-column col-md-4">
        <p class="lead text-white">Legales</p>
        <a class="text-white fw-light small mb-2" *ngFor="let legal of legales" [routerLink]="legal.url">{{legal.nombre}}</a>
      </div>
      <div class="col-md-4">
        <p class="lead text-white fw-light">Contacto</p>
        <div class="text-white small d-flex flex-column">
          <a class="text-reset mb-2" href="tel:995410104">C. 995410104</a>
          <a class="text-reset mb-2" href="mailto:info@seguros360.pe" target="blank">info@seguros360.pe</a>
          <a class="text-reset mb-2" href="https://goo.gl/maps/cuxu4tMnUxheCjcT9" target="blank">Calle Ramón Ribeyro 890, Barranco - Lima</a>
        </div>
      </div>
    </div>
    <hr>
    <div class="text-center text-white fw-light small pt-3">
      <p class="opacity-75 mb-0">Seguros 360 es operado por Eagles Corredores de Seguros S.A.C.</p>
      <p class="opacity-75 my-1">Registro SBS J-0810</p>
      <p class="opacity-25 mt-3 extraSmall">Desarrollado por DAGDA</p>
    </div>
    
  </div>  
</div>