<!-- <div class="">
  <iframe src="https://missegurosdigitales.seguros360.pe/Interconexion/Web/PersonValidate" frameborder="0" width="100%" height="1200px"></iframe>
</div> -->


<!-- <div class="container">

  <div class="row">
    <div class="col-md-6">
      fdgdf
    </div>
    <div class="col-md-6">
      <form [formGroup]="formVehicular" (submit)="onSubmit()" novalidate class="row">
        <p class="lead">Datos del vehículo</p>
        <mat-form-field class="col-12">
          <mat-label>Marca</mat-label>
          <input matInput type="text">
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Modelo</mat-label>
          <input matInput type="text">
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Zona circulación</mat-label>
          <input matInput type="text">
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Año de fabricación</mat-label>
          <input matInput type="text">
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Valor del vehículo</mat-label>
          <input matInput type="text">
        </mat-form-field>
        <p class="lead">Datos de contacto</p>
      </form>
    </div>
  </div>

</div> -->



<div class="backCotizador">

  <div class="container">

    <div class="row">

      <div class="col-md-6 bg-primary">
        <div class="text-white p-3 py-md-7">
          <p class="h1 mb-4">Asesórate y contrata el seguro adecuado para tu auto, elige las coberturas que realmente necesitas...</p>
          <!-- <p class="lead">Compara los precios de cada aseguradora</p> -->
          <div class="d-flex mb-2" *ngFor="let item of points">
            <span class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#06AB3E" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </span>
            <div class="pt-1">
              <span class="fw-light">{{item}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6  bg-light">
        <div class="p-2 ps-md-7 py-md-7 pe-md-0 pt-4">
          <form [formGroup]="formVehicular" (submit)="onSubmit()" novalidate class="row gx-2 gx-md-3">
            <p class="h5">Datos del vehículo</p>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Marca</mat-label>
              <input matInput type="text" formControlName="marca">
            </mat-form-field>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Modelo</mat-label>
              <input matInput type="text" formControlName="modelo">
            </mat-form-field>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Zona circulación</mat-label>
              <input matInput type="text" formControlName="zonaCirculacion">
            </mat-form-field>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Año de fabricación</mat-label>
              <input matInput type="text" formControlName="anioFabricacion">
            </mat-form-field>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>Valor del vehículo</mat-label>
              <input matInput type="text" formControlName="valorVehiculo">
            </mat-form-field>
            <p class="h5">Datos de contacto</p>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email">
            </mat-form-field>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Celular</mat-label>
              <input matInput formControlName="celular">
            </mat-form-field>
            <mat-form-field class="col-6" appearance="outline">
              <mat-label>Edad</mat-label>
              <input matInput type="number" formControlName="edad">
            </mat-form-field>

            <div class="d-grid mt-3">
              <button class="btn btn-secondary">
                <span class="d-flex justify-content-center myColorSpinnerWhite" *ngIf="loading">
                  <mat-spinner class="" [diameter]="24"></mat-spinner>
                </span>
                <span *ngIf="!loading">Enviar datos</span>
              </button>
            </div>

          </form>
        </div>
      </div>

    </div>

  </div>

</div>

<div class="p-4 p-md-7 text-center">
  <p class="lead mb-0">¿Dudas con el Seguro Vehicular?</p>
  <a href="https://api.whatsapp.com/send?phone=51995410104&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro Vehicular"
    target="blank">Cuéntanos</a>
</div>