import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DataService } from 'src/app/services/data.service';
import { ModalCotizadoresComponent } from '../modal-cotizadores/modal-cotizadores.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links = [
    {
      nombre: 'Seguros',
      url: '/seguros'
    },
    {
      nombre: 'Nosotros',
      url: '/nosotros'
    },
    {
      nombre: 'Contacto',
      url: '/contacto'
    },
    // {
    //   nombre: 'Blog',
    //   url: '/blog'
    // },
  ]

  cotizadores = []

  constructor(
    private bottomSheet: MatBottomSheet,
    private ds: DataService
  ) { }

  ngOnInit(): void {

    this.cotizadores = this.ds.cotizadores;
  }

  openBottomSheetTourFijos() {
    this.bottomSheet.open(ModalCotizadoresComponent, {
      data: this.cotizadores
    });
  }

}
