<div class="container pb-5 pb-md-7">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb extraSmall mb-3 mb-md-4">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active">Contacto</li>
    </ol>
  </nav>

  <div class="row py-4 py-md-5 justify-content-between">
    <div class="col-md-5">
      <p class="h4">En Seguros 360 sabemos que la buena comunicación es importante.</p>
      <p class="text-muted">¿Necesitas ayuda con algún seguro? ¿Tuviste algún siniestro? Recuerda que nosotros te asesoramos con cualquier tema relacionado con tu seguro.</p>
    </div>
    <div class="col-md-5">
      <p class="lead text-dark fw-light">Conversemos</p>
      <div class="text-primary d-flex flex-column">
        <div>
          <a class="btn btn-primary myBtn mb-2" href="tel:995410104">Llámanos al 995410104</a>
        </div>
        <div>
          <a class="btn btn-outline-secondary myBtn mb-2" href="https://api.whatsapp.com/send?phone=51995410104&text=Hola, te escribo desde la web de Seguros 360/contacto" target="blank">Hablemos por WhatsApp
            <span class="ms-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FF5F0D" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
              </svg>
            </span>
           
          </a>
        </div>
       
        <a class="text-reset mb-2" href="mailto:info@seguros360.pe" target="blank">info&#64;seguros360.pe</a>
        <a class="text-reset mb-2" href="https://goo.gl/maps/cuxu4tMnUxheCjcT9" target="blank">Calle Ramón Ribeyro 890, Barranco - Lima</a>
      </div>
    </div>
  </div>

</div>