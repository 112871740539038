<div class="backBannerHome">
  <div class="myBlurBack">

    <div class="container py-4 py-md-6">

      <div class="row d-flex flex-column flex-md-row justify-content-md-between align-items-center">

        <div class="col-md-5">

          <!-- <p class="h3 text-white fw-light mb-3 mb-md-4 text-center text-md-start">Sin preocupaciones, es como queremos que vivas</p> -->

          <img class=""
            src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/latinoCelular.png?alt=media&token=e0e33f9c-0af3-4d1a-8485-b90e88f6b2ef"
            alt="" width="100%">
        </div>

        <div class="col-md-6">

          <!-- <div class="text-white mb-3 mb-4 text-center text-md-start">
            <p class="h2 mb-0">Cotiza al instante</p>
            <p class="fw-light opacity-50">Obtén el precio final de tu seguro, sin demoras</p>
          </div> -->





          <!-- <div class="myBoxCotizadorHome p-3 my-2 d-flex align-items-center position-relative"
            *ngFor="let item of cotizadores">
            <div class="me-3">
              <img [src]="item.icon" width="60px">
            </div>
            <div class="text-white flex-grow-1">
              <p class="mb-0 opacity-50 gorro small text-uppercase">{{item.gorro}}</p>
              <p class="h4">{{item.nombre}}</p>
            </div>
            <div class="ms-5 d-none d-md-block">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/icons%2Farrow-narrow-right.svg?alt=media&token=827f6d17-33f7-4cc0-a28f-efcc2599c912"
                alt="">
            </div>
            <a class="stretched-link" [routerLink]="item.url"></a>
          </div> -->



          <p class="fs-1 text-white fw-light mb-3 mb-md-4 text-center text-md-start">Sin preocupaciones, es como queremos que vivas</p>




        </div>

      </div>
    </div>
  </div>
</div>





<div id='soat' class="bg-light py-5 py-md-6">
  <div class="container">
    <div class="row align-items-center gx-md-6 gy-4">
      <div class="col-md-6 text-center text-md-start">
        <p class="display-4 fw-bold">Compra tu SOAT desde S/. 44</p>
        <!-- <p class="lead">Disponible con Pacífico</p> -->

        <a class="btn btn-secondary" routerLink="/soat">Compra tu SOAT Virtual</a>
        <!-- <a class="btn btn-success"
          href="https://soat.mapfre.com.pe/cotiza-intermediario/AF60AE64E9060876E0530AA0785452BD"
          target="_blank">Comprar con MAPFRE</a> -->

      </div>
      <div class="col-md-6">
        <img class="w-100"
          src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imgSoatEagles.png?alt=media&token=8ba569ad-50bf-4ee1-866a-180f8a27cc8a"
          alt="">
      </div>
    </div>
  </div>
</div>


<div class="container text-center my-6 my-md-8">
  <p class="h4 fw-light">Trabajamos con todas las aseguradoras</p>
  <div class="col-md-8 mx-auto mt-5">
    <div class="grillaAseguradorasHome">
      <div *ngFor="let aseguradora of aseguradoras">
        <img [src]="aseguradora.logo" alt="" width="100%">
      </div>
    </div>
  </div>
</div>







<!-- <div class="bg-light">
  <div class="container py-4 py-md-7">
    <app-noticias-block></app-noticias-block>
  </div>
</div> -->















<div class="container text-center my-5 my-md-6">
  <p class="h3 fw-light">Te ayudamos a proteger lo que más te interesa</p>
  <p class="lead">¿Buscas un seguro? <a class="text-secondary"
      href="https://api.whatsapp.com/send?phone=51995410104&text=Hola, te escribo desde la web de Seguros 360/inicio"
      target="blank">Conversemos</a></p>
</div>







<div class="container mb-4 mb-md-6">
  <div class="row gy-3 gy-md-5">
    <div class="col-12 " *ngFor="let item of categorias">
      <div class="categoriaHome ">
        <div class="row justify-content-between">
          <div class="col-md-5 p-4 p-md-6">
            <p class="h2 mb-3">{{item.titulo}}</p>
            <div class="d-flex py-1" *ngFor="let seguro of item.seguros">
              <div class="me-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right"
                  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#1446A3" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="15" y1="16" x2="19" y2="12" />
                  <line x1="15" y1="8" x2="19" y2="12" />
                </svg>
              </div>
              <div><a class="text-secondary" [routerLink]="seguro.url">{{seguro.nombre}}</a></div>
            </div>
          </div>
          <div class="col-md-6">
            <img class="" [src]="item.image" width="100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







<div class="bg-light">
  <div class="container py-5 py-md-7">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="text-center text-md-start">
        <p class="h2">Somos un corredor de seguros enfocado en que vivas #SinPreocupaciones</p>
        <p>Si tienes una empresa o quieres proteger tu salud y tus bienes, ¡Nosotros te ayudamos!</p>
        <a class="btn btn-outline-secondary myBtn" routerLink="/nosotros">Conoce más</a>
      </div>
      <div class="">
        <div class="d-flex justify-content-center">
          <img class="imgSinPreocupaciones"
            src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/sinPreocupaciones.png?alt=media&token=d6f89d82-63af-4155-ad25-e71146a3f762"
            alt="">
        </div>
      </div>
    </div>
  </div>
</div>






<app-socios></app-socios>


<div class="bg-light">
  <div class="container py-5 py-md-6">
    <p class="h1 text-center mb-5 mb-md-6">Testimonios</p>
    <div class="grillaTestimonios">
      <div *ngFor="let testimonio of testimonios">
        <p class="h5 text-primary">{{testimonio.nombre}}</p>
        <p class="small text-muted">{{testimonio.cargo}}</p>
        <div class="d-flex">
          <span class="me-3">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/comillasApertura.svg?alt=media&token=07e5e7cd-604f-4d70-895d-a6e5327aa6f3"
              alt="">
          </span>
          <div class="rounded-3">
            <p class="small mb-0 fw-light">{{testimonio.comentario}}</p>
          </div>
        </div>

        <!-- <span class="text-end">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/comillasCierre.svg?alt=media&token=4bbb4685-2a1f-4f8f-a443-af5f8916d660"
            alt="">
        </span> -->
      </div>
    </div>
  </div>
</div>