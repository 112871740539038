import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog } from '@angular/material/dialog';
import { MeInteresaComponent } from '../../me-interesa/me-interesa.component';
import { CartillaSaludComponent } from '../cartilla-salud/cartilla-salud.component';
import { ClinicasComponent } from '../clinicas/clinicas.component';
import { CoberturasSaludComponent } from '../coberturas-salud/coberturas-salud.component';

@Component({
  selector: 'app-card-resultado-salud',
  templateUrl: './card-resultado-salud.component.html',
  styleUrls: ['./card-resultado-salud.component.scss']
})
export class CardResultadoSaludComponent implements OnInit {

  @Input() item;
  @Input() formSalud;

  mensajeWhatsApp: string;

  constructor(
    private dialog: MatDialog,
    private analytics: AngularFireAnalytics
  ) { }

  
  ngOnInit(): void {
    this.mensajeWhatsApp = `https://api.whatsapp.com/send?phone=51995410104&text=Hola, soy ${this.formSalud.get('nombre').value} te escribo desde la web de Seguros 360, necesito que me asesoren con un seguro de Salud *${this.item.plan}* con *${this.item.aseguradora}*. La edad del titular es ${this.formSalud.get('edadTitular').value} años. Familiares: ${this.item.familiares.length > 0 ? `${this.item.familiares.map( familiar => `${familiar.vinculo} de ${familiar.edad} años`)}` : 'Ninguno'}. A un costo aproximado de S/. ${this.item.prima} por mes`;
  }

  openModalClinicas() {
    this.dialog.open(ClinicasComponent, {
      panelClass: 'modalDetalleSeguroSalud',
      data: this.item
    });
  }

  openModalCoberturas() {
    const dialogRef = this.dialog.open(CoberturasSaludComponent, {
      panelClass: 'modalDetalleSeguroSalud',
      data: this.item
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.animal = result;
    // });
  }

  // openModalContactoSeguroSalud() {
  //   this.analytics.logEvent('botonMeInteresaSalud');

  //   this.dialog.open(FormContactoSaludComponent, {
  //     // panelClass: 'modalContactoSeguroSalud',
  //     panelClass: 'dialogDeleteSolicitud',
  //     data: this.item
  //   });
  // }

  openModalMeInteresa() {
    this.dialog.open(MeInteresaComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: {
        item_: this.item,
        formSalud_: this.formSalud
      }
    });
  }

  openModalCartilla() {
    this.dialog.open(CartillaSaludComponent, {
      panelClass: 'modalContactoSeguroSalud',
      data: this.item
    });
  }

  goToCartilla(cartilla) {
    // window.location.href = `${cartilla}`;
    window.open(`${cartilla}`, '_blank');
  }

  goToCondicionado(consolidado) {
    window.open(`${consolidado}`, '_blank');
  }

}
