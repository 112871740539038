<div class="bg-white py-5 py-md-7">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="text-center mb-4 mb-md-5">
          <p class="h1">Nuestros Líderes</p>
          <p>Somos un equipo multidisciplinario de profesionales técnicos y comerciales con amplia experiencia</p>
        </div>
        <div class="grillaSocios">
          <div *ngFor="let socio of socios">
            <div class="mb-3 bg-light rounded-circle p-2">
              <img class="rounded-circle" [src]="socio.image" width="100%">
            </div>
            <div class="d-flex">
              <div class="me-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FF5F0D" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="13" y1="18" x2="19" y2="12" />
                  <line x1="13" y1="6" x2="19" y2="12" />
                </svg>
              </div>
              <div>
                <p class="h4 fw-bold">{{socio.nombre}}</p>
                <p class="h5">{{socio.cargo}}</p>
                <p class="text-muted small my-3">{{socio.descripcion}}</p>
                <a [href]="socio.url" target="blank" class="btn btn-sm btn-outline-primary myBtn">Ver perfil Linkedin</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>